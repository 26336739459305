import image1 from './assets/school staff/1.jpg';
import image2 from './assets/school staff/2.jpg';
import image3 from './assets/school staff/3.jpg';
import image4 from './assets/school staff/4.jpg';
import image5 from './assets/school staff/5.jpg';
import image6 from './assets/school staff/6.jpg';
import image7 from './assets/school staff/7.jpg';
import image8 from './assets/school staff/8.jpg';
import image9 from './assets/school staff/9.jpg';
import image10 from './assets/school staff/10.jpg';
import image11 from './assets/school staff/11.jpg';
import image12 from './assets/school staff/12.jpg';
import image13 from './assets/school staff/13.jpg';
import image14 from './assets/school staff/14.jpg';
import image15 from './assets/school staff/15.jpg';
import image16 from './assets/school staff/16.jpg';
import image17 from './assets/school staff/17.jpg';
import image18 from './assets/school staff/18.jpg';
import image19 from './assets/school staff/19.jpg';
import image20 from './assets/school staff/20.jpg';
import image21 from './assets/school staff/21.jpg';
import image22 from './assets/school staff/22.jpg';
import image23 from './assets/school staff/23.jpg';

export default [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
];
